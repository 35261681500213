import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { DivergenciaType } from "../detail/DivergenciaDetailTypes";
import { UPDATE_CULPABILIDADE } from "../mutations/updateCulpabilidadeSoma.mutation";
import { useMutation } from "@apollo/client";
import { DivergenceContext, DivergenceContextType } from "../../../../context";

interface CulpabilidadeSomaSelectorProps {
  id_divergencia: number;
  divergencia: DivergenciaType;
  culpabilidade_default: boolean;
}

export const CulpabilidadeSomaSelector: FunctionComponent<CulpabilidadeSomaSelectorProps> = ({
  id_divergencia,
  divergencia,
  culpabilidade_default,
}) => {
  const { handleCulpabilidade } = useContext<DivergenceContextType>(DivergenceContext);
  const [culpabilidadeType, setCulpabilidadeType] = useState<string>(
    divergencia.culpabilidade_soma === true
      ? "Soma"
      : divergencia.culpabilidade_soma === false
      ? "Fornecedor"
      : ""
  );

  useEffect(() => {
    culpabilidade_default ? setCulpabilidadeType("Soma")
    : setCulpabilidadeType(
      divergencia.culpabilidade_soma === true
        ? "Soma"
        : divergencia.culpabilidade_soma === false
        ? "Fornecedor"
        : ""
    );
  }, [culpabilidade_default, divergencia.culpabilidade_soma]);

  const [updateCulpabilidade, { loading }] = useMutation(UPDATE_CULPABILIDADE);
  
  const handleCulpabilidadeChange = async (culpabilidadeType: string) => {
    const culpabilidadeSoma =
      culpabilidadeType === "Soma"
        ? true
        : culpabilidadeType === "Fornecedor"
        ? false
        : null;

    try {
      await updateCulpabilidade({
        variables: {
          id_divergencia,
          culpabilidade_soma: culpabilidadeSoma,
        },
      });
      handleCulpabilidade(culpabilidadeSoma);
    } catch (error) {
      console.error("Erro ao atualizar culpabilidade", error);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newType = event.target.value as string;
    setCulpabilidadeType(newType);
    handleCulpabilidadeChange(newType);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="culpabilidade-select-label">
          {loading ? "Atualizando..." : "Selecione a culpabilidade"}
        </InputLabel>
        <Select
          labelId="culpabilidade-select-label"
          id="culpabilidade-select"
          value={culpabilidadeType}
          onChange={handleSelectChange}
          disabled={culpabilidade_default || loading}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value="Fornecedor">Fornecedor</MenuItem>
          <MenuItem value="Soma">Soma</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

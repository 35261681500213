import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import { loader } from "graphql.macro";
import React, { FunctionComponent, useContext, useEffect } from "react";
import DivergenciaStatusEnum from "../../../../../support/enums/DivergenciaStatusEnum";
import { StatusEnum } from "../../components/DivergenciasTable";
import LabelValueBase from "../../nova-divergencia/components/LabelValueBase";
import { DivergenciaType } from "../DivergenciaDetailTypes";
import { isSupplier } from "../../../../../tools";
import { UserContext, UserContextType, DivergenceContext, DivergenceContextType } from "../../../../../context";
import { Alert } from "@material-ui/lab";

const useStylesStatus = makeStyles({
  NOVO: {
    color: "#07c604",
  },
  EM_ANDAMENTO: {
    color: "#FFB800",
  },
  AGUARDANDO_FINALIZACAO: {
    color: "#07C604",
  },
  FINALIZADO: {
    color: "#949394",
  },
});

const updateStatusMutation = loader(
  "../../queries/UpdateStatusDivergencia.gql"
);

const userCanChangeStatusQuery = loader(
  "../../queries/GetLoggedUserCanChangeDivergenciaStatus.gql"
);

const InfoStatus: FunctionComponent<{
  divergencia: DivergenciaType;
  onStatusChange: Function;
}> = ({ divergencia, onStatusChange }) => {
  const { culpabilidade } = useContext<DivergenceContextType>(DivergenceContext);
  const classes = useStylesStatus();
  const status = divergencia.status;

  const statusTexto = divergencia.status === DivergenciaStatusEnum.NOVO
    ? "Aguardando finalização"
    : divergencia.status === DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO
      ? "Em andamento"
      : "Aguardando finalização";


  const statusClass = clsx(
    "caption",
    "bold",
    "d-flex",
    "align-item-center",
    classes[status as keyof typeof classes]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    loading: loadingCanChangeStatusQuery,
    data: userCanChangeStatus,
    refetch: refetchCanChange,
  } = useQuery<{ canChangeDivergenciaStatus: boolean }>(
    userCanChangeStatusQuery,
    {
      variables: {
        idDivergencia: divergencia.id_divergencia,
      },
    }
  );

  useEffect(() => {
    refetchCanChange();
  }, [divergencia.status, refetchCanChange]);

  const { userLogged } = useContext<UserContextType>(UserContext);

  const [save, { loading: loadingSave }] = useMutation(updateStatusMutation);
  const finalizar = () => {
    if (loadingSave) return;

    save({
      variables: {
        idDivergencia: divergencia.id_divergencia,
        novoStatus:
          divergencia.status === DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO
            ? DivergenciaStatusEnum.EM_ANDAMENTO
            : DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO,
      },
    }).then(() => {
      handleClose();
      onStatusChange();
      refetchCanChange();
    });
  };

  const canChangeStatus = () => {
    /* if (divergencia.status !== DivergenciaStatusEnum.EM_ANDAMENTO) {
      return false;
    } */
    if (
      (divergencia.status === DivergenciaStatusEnum.NOVO ||
        divergencia.status === DivergenciaStatusEnum.EM_ANDAMENTO) &&
      !isSupplier(userLogged?.id_grupo || 0)
    ) {
      return true;
    }

    if (
      divergencia.status === DivergenciaStatusEnum.AGUARDANDO_FINALIZACAO &&
      !isSupplier(userLogged?.id_grupo || 0)
    ) {
      return true;
    }

    if (loadingCanChangeStatusQuery) {
      return false;
    }
    return userCanChangeStatus?.canChangeDivergenciaStatus || false;
  };

  const statusText = StatusEnum[status as keyof typeof StatusEnum];
  return (
    <LabelValueBase label="Status">
      <div className={statusClass}>
        <span>{statusText}</span>
        {canChangeStatus() && (
          <Button onClick={handleClick} disabled={culpabilidade === null}>
            <span className="overline bold neutral400">ALTERAR</span>
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          id="status-menu"
          keepMounted
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuItem onClick={finalizar}>
            {loadingSave ? (
              <div style={{ textAlign: "center", width: "100%" }}>
                <CircularProgress color="inherit" size={18}></CircularProgress>
              </div>
            ) : (
              statusTexto
            )}
          </MenuItem>
        </Menu>
      </div>
      {(culpabilidade === null) && (
          <div>
            <Alert severity="warning">
              Necessário preencher o campo de culpabilidade para alterar o status do chamado.
            </Alert>
          </div>
        )
      }
    </LabelValueBase>
  );
};

export default InfoStatus;

import { gql } from '@apollo/client';

export const UPDATE_CULPABILIDADE = gql`
  mutation updateCulpabilidadeSoma(
    $id_divergencia: Int!
    $culpabilidade_soma: Boolean
  ) {
    updateCulpabilidadeSoma(
      id_divergencia: $id_divergencia
      culpabilidade_soma: $culpabilidade_soma
    ) {
      id_divergencia
      culpabilidade_soma
    }
  }
`;

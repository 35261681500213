import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemIcon,
  makeStyles,
  ListItemText,
  Theme,
  Typography,
  ListItem,
  Button
} from "@material-ui/core";
import { InsertDriveFile as FileIcon } from '@material-ui/icons';
import CloseIcon from "@material-ui/icons/Close";
import { loader } from "graphql.macro";
import _get from "lodash.get";
import sortBy from "lodash.sortby";
import moment from "moment";
import React, { FunctionComponent, useContext, useEffect } from "react";
import { ReactComponent as DoneIcon } from "../../../../../assets/icons/done.svg";
import UserAvatar from "../../../../support/components/UserAvatar";
import { default as StatusDivergenciaEnum } from "../../../../support/enums/DivergenciaStatusEnum";
import {
  buildDivergenciaNumber,
  useQueryString,
} from "../../../../support/Utils";
import ColaboradorResponsavelComponent from "../components/ColaboradorResponsavelComponent";
import LabelValueComponent from "../nova-divergencia/components/LabelValueComponent";
import TipoDivergenciasInfoKey from "../nova-divergencia/TipoDivergenciasInfoKey.";
import DivergenciaDetailChat from "./components/DivergenciaDetailChat";
import FinalizarDivergenciaButton from "./components/FinalizarDivergenciaButton";
import InfoComponent from "./components/InfoComponent";
import InfoStatus from "./components/InfoStatus";
import LogInfo from "./components/LogInfo";
import MateriaisPopover from "./components/MateriaisPopover";
import MensagemComponent from "./components/MensagemComponent";
import NotaFiscalComponent from "./components/NotaFiscalComponent";
import TipoDivergencia from "./components/TipoDivergencia";
import SituacaoDivergencia from "./components/SituacaoDivergencia";
import AreaRespSecundaria from "./components/AreaRespSecundaria";
import CategoriaAreaRespSecundaria from "./components/CategoriaAreaRespSecundaria";
import SetorPendenciaCausaRaiz from "./components/SetorPendenciaCausaRaiz";
import {
  DivergenciaInfo,
  DivergenciaType,
  Log,
  Mensagem,
  NotaFiscalSaidaOS,
  StatusTransportadora,
  TipoLog,
} from "./DivergenciaDetailTypes";
import SubgroupSelect from "./components/subgroupSelect/SubgroupSelect";
import ReminderDate from './components/Reminders/Reminder'
import { FineComponent } from './components/fine'
import { isOverdue, isRenderDeliveryStatus } from '../../../divergencia-list/components/functions'
import { DivergenceContext, DivergenceContextType, UserContext, UserContextType } from "../../../../context";
import { ObsAreaRespSecundaria } from "./components/ObsAreaRespSecundaria";
import { isSupplier } from "../../../../tools";
import { useConfiguracaoByKey } from "../../../../support/UseConfiguracao";
import { ConfiguracoesKeys } from "../../../../support/enums/Configuracoes";
import FeedbackSnackbar from "../../../../shared/components/UI/FeedbackSnackbar/FeedbackSnackbar";
import { CulpabilidadeSomaSelector } from "../culpabilidade/CulpabilidadeSomaSelector";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 99999,
      color: "#fff",
    },
  })
);

const BarraColor: FunctionComponent<{
  color?: string;
  text?: string;
}> = ({ color, text }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: "100%",
        height: "5px",
        display: "block",
        position: "absolute",
        left: "1px",
        marginTop: "-10px",
      }}
    >
      {text}
    </div>
  );
};

const query = loader("../queries/GetDivergencia.gql");
const queryNFSaida = loader("../queries/GetNotaFiscalSaida.gql");
const queryStatusTransportadora = loader(
  "../queries/GetStatusTransportadoraOpNf.gql"
);

const DivergenciaDetail: FunctionComponent<{
  open: boolean;
  onClose: Function;
  onUpdatedDivergencia: Function;
}> = (props) => {
  const queryString = useQueryString();
  const idDivergencia = Number(queryString.get("open"));
  const { userLogged } = useContext<UserContextType>(UserContext);
  const classes = useStyles();
  
  const { data: configData } = useConfiguracaoByKey(ConfiguracoesKeys.RESPONSAVEL_AREA_SECUNDARIA);
  const { truncateString, downloadFileFromGCS, setShowSetor, handleCloseSnackbarAnalise, feedbackAnalise, handleCulpabilidade } = useContext<DivergenceContextType>(DivergenceContext);
  
  const { loading, data, refetch } = useQuery<{
    findOnePLM_Colaborativo_Divergencias: DivergenciaType;
  }>(query, {
    variables: {
      idDivergencia: idDivergencia,
    },
  });

  useEffect(() => {
    handleCulpabilidade(data?.findOnePLM_Colaborativo_Divergencias?.culpabilidade_soma as any)
  }, [data, handleCulpabilidade])
 

  //NF de saida
  const { data: dataNF } = useQuery<{
    findOneNota_Fiscal_Saida_OS: NotaFiscalSaidaOS;
  }>(queryNFSaida, {
    variables: {
      idDivergencia: idDivergencia,
      idOrdemProducao:
        data?.findOnePLM_Colaborativo_Divergencias?.Producao_PPP_OP
          ?.Ordem_Producao?.id_ordem_producao || 0,
    },
  });

  let notaFiscalSaida = {} as NotaFiscalSaidaOS;

  if (dataNF) {
    notaFiscalSaida = dataNF?.findOneNota_Fiscal_Saida_OS as NotaFiscalSaidaOS;
  }

  // Status Transportadora
  const { data: dataStatusTransportadora } = useQuery<{
    findOnePLM_Colaborativo_Status_OP_NF: StatusTransportadora;
  }>(queryStatusTransportadora, {
    variables: {
      idDivergencia: idDivergencia,
    },
  });

  let statusTransportadora = {} as StatusTransportadora;

  if (dataStatusTransportadora) {
    statusTransportadora =
      dataStatusTransportadora?.findOnePLM_Colaborativo_Status_OP_NF as StatusTransportadora;
  }

  const reload = () => {
    props.onUpdatedDivergencia();
    refetch()
    .catch((error) => console.log(error));
  };

  let infoComponents: Array<JSX.Element> = [];
  const divergenciasInfosComponents: JSX.Element[] = [];
  const materialInfos: DivergenciaInfo[] = [];

  let divergencia = {} as DivergenciaType;
  if (data?.findOnePLM_Colaborativo_Divergencias) {
    divergencia = data.findOnePLM_Colaborativo_Divergencias as DivergenciaType;
    infoComponents = buildDefaultInfoComponents(
      divergencia,
      statusTransportadora,
      () => {
        reload();
      },
      userLogged,
      configData
    );

    const { created_at, Producao_PPP_OP, TipoDivergencia, Multas } = divergencia
    const paramsIsRenderDeliveryStatus = {
      creationDate: created_at,
      idBrand: Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.Produto.Marca.id_marca,
      idTypeDivergence: TipoDivergencia.id_tipo_divergencia,
      scheduledDeliveryDate: Producao_PPP_OP.Ordem_Producao?.primeira_data_entrega_insumos
    }
    const paramsIsOverdue = {
      creationDate: created_at,
      scheduledDeliveryDate: Producao_PPP_OP.Ordem_Producao?.primeira_data_entrega_insumos
    }

    const shouldRenderDeliveryStatus = isRenderDeliveryStatus({ ...paramsIsRenderDeliveryStatus });
    const isOrderOverdue = isOverdue({ ...paramsIsOverdue });
    if (shouldRenderDeliveryStatus && isOrderOverdue) {
      infoComponents.push(
        <FineComponent
          fineDataProps={
            {
              idDivergence: divergencia.id_divergencia,
              productionOrder: Producao_PPP_OP.Ordem_Producao.ordem_producao,
              idFine: Multas?.id_multa,
              fine: Multas?.multa,
              observation: Multas?.observacao
            }
          }
          reload={reload}
        />
      );
    }

    divergencia.DivergenciaInfos.forEach((info) => {
      const groupTypes: TipoDivergenciasInfoKey[] = [
        TipoDivergenciasInfoKey.AVIAMENTO,
        TipoDivergenciasInfoKey.OUTROS_AVIAMENTOS,
        TipoDivergenciasInfoKey.OUTROS_TECIDOS,
        TipoDivergenciasInfoKey.TECIDO,
      ];

      if (groupTypes.includes(info.tipo as TipoDivergenciasInfoKey)) {
        materialInfos.push(info);
      } else {
        divergenciasInfosComponents.push(
          <InfoComponent info={info}></InfoComponent>
        );
      }
    });

    infoComponents.push(...divergenciasInfosComponents);

    const { DivergenciaArquivos } = data.findOnePLM_Colaborativo_Divergencias

    if (DivergenciaArquivos && DivergenciaArquivos.length > 0) {
      DivergenciaArquivos.map((file, index) => (
        infoComponents.push(
          <ListItem key={index} button>
            <ListItemIcon>
              <FileIcon />
            </ListItemIcon>
            <ListItemText
              primary={'anexo'}
              secondary={truncateString(file.nome_arquivo as string, 18)}
            />
            <IconButton edge="end" onClick={() => downloadFileFromGCS(file.url, file.nome_arquivo)}>
              <Typography variant="caption" color="primary">
                Baixar
              </Typography>
            </IconButton>
          </ListItem>
        )
      ))
    }

    if (materialInfos.length > 0) {
      infoComponents.push(
        <Box style={{ display: 'flex', justifyContent: 'flex-start', margin: '1rem auto' }}>
          <MateriaisPopover materiais={materialInfos}></MateriaisPopover>
        </Box>
      );
    }
    // TODO - Tratamento também presente no backend, alterar posteriormente
    const tiposCulpabilidadeSomaDefault = [2, 3, 8, 11, 12, 13, 15, 16, 20, 24, 25, 26, 32, 33, 34, 41, 42, 43, 56, 58, 61, 62, 63, 65, 70, 72, 85, 86];
    const isCulpabilidadeSoma = tiposCulpabilidadeSomaDefault.includes(
      divergencia.TipoDivergencia?.id_tipo_divergencia
    );
    infoComponents.push(
      <CulpabilidadeSomaSelector divergencia={divergencia} id_divergencia={divergencia.id_divergencia} culpabilidade_default={isCulpabilidadeSoma} />
    );
    
  }

  if (!loading && !divergencia.id_divergencia) {
    return <div></div>;
  }

  const isAguardandoFinalizacao =
    divergencia.status === StatusDivergenciaEnum.AGUARDANDO_FINALIZACAO;
  const isFinalizado = divergencia.status === StatusDivergenciaEnum.FINALIZADO;

  let logsAndMesssages: {
    tipo: string;
    value: Mensagem | Log | NotaFiscalSaidaOS;
    data: Date;
  }[] = [];

  let divergenceFinalized
  if (divergencia.id_divergencia) {
    logsAndMesssages = [
      ...divergencia.Logs.map((item) => ({
        tipo: "log",
        value: item,
        data: item.created_at,
      }))
        .filter((item) =>
          (item.value.tipo !== TipoLog.FINALIZACAO_DIVERGENCIA)
        ),
      ...divergencia.Mensagens.map((item) => ({
        tipo: "mensagem",
        value: item,
        data: item.created_at,
      })),
    ];
    divergenceFinalized = divergencia.Logs.find((item) =>
      (item.tipo === TipoLog.FINALIZACAO_DIVERGENCIA)
    )
  }
  const sorted = sortBy(logsAndMesssages, ["data"]);
    const logsAndMessagesSortedDesc = sorted.reverse();

  const analiseChamadosCorte = divergencia.UsuarioAbriu?.id_usuario === 9949 && divergencia.UsuarioResponsavel?.id_usuario === 10947

  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
          <Dialog
            disableBackdropClick={true}
            id="divergenciaDetail"
            maxWidth="md"
            open={props.open}
            PaperProps={{
              style: {
                overflowX: 'hidden',
              },
            }}
          >
          <DialogTitle
            className="divergenciaMaxWidth"
            style={{
              padding: "24px 32px",
            }}
          >
            <div className="d-flex align-item-center">
              <DoneIcon
                style={{ width: "32px", height: "32px" }}
                className="iconPrimary500"
              ></DoneIcon>
                
              <span className="ml16">
                <div
                  className="h5 headline neutral700 lineHeight28"
                  style={{
                    maxWidth: "500px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={divergencia.TipoDivergencia.tipo}
                >
                  {buildDivergenciaNumber(divergencia.id_divergencia)} -{" "}
                  {divergencia.TipoDivergencia.tipo}
                </div>
                <div className="body2 bold neutral400 d-flex align-item-center">
                  <div>
                    OP:{" "}
                    {_get(
                      divergencia,
                      "Producao_PPP_OP.Ordem_Producao.ordem_producao",
                      "-"
                    )}
                  </div>
                  <div className="mx8"> | </div>
                  <div>
                    PRODUTO:{" "}
                    {_get(
                      divergencia,
                      "Producao_PPP_OP.Producao_Prog_Prod.Produto_Cor.Produto.produto",
                      "-"
                    )}
                  </div>
                </div>
              </span>
              <IconButton
                className="mlAuto"
                aria-label="close"
                onClick={() => {
                  props.onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent
            className="backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth d-flex flexColumn"
            style={{ padding: "5px 32px" }}
          >
            <BarraColor
              text=""
              color={
                data?.findOnePLM_Colaborativo_Divergencias?.Producao_PPP_OP
                  ?.FarolOneBeat?.buffer_penetration_color
              }
            />
            <Box padding="0 16px" className="flexGrow1 d-flex flexColumn">
              <Grid container spacing={3}>
                {infoComponents.map((item, index) => (
                  <Grid item xs={6} key={`info-${index}`}>
                    {item}
                  </Grid>
                ))}

                { analiseChamadosCorte ? 
                  <>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: 'auto 12px auto auto', height: '36.5px' }}>
                      <Button
                        variant="contained"
                        className="backgroundPrimary500"
                        onClick={() => setShowSetor(true)}
                      >
                        <span className="text-transform-initial neutral000 bold">
                          Análise Chamados Corte
                        </span>
                      </Button>
                    </Box>

                    <SetorPendenciaCausaRaiz
                      divergencia={divergencia}
                    /> 
                  <FeedbackSnackbar feedback={feedbackAnalise} onClose={handleCloseSnackbarAnalise} />

                  </> : null
                }
                <Grid item xs={12}>
                  {isAguardandoFinalizacao && (
                    <FinalizarDivergenciaButton
                      divergencia={divergencia}
                      onStatusChange={reload}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!isFinalizado && (
                    <div className="d-flex">
                      <UserAvatar></UserAvatar>
                      <DivergenciaDetailChat
                        divergenciaId={divergencia.id_divergencia}
                        onNewMessage={reload}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {divergenceFinalized && (
                    <LogInfo log={divergenceFinalized as Log}></LogInfo>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {notaFiscalSaida && (
                    <NotaFiscalComponent
                      notafiscal={notaFiscalSaida as NotaFiscalSaidaOS}
                    ></NotaFiscalComponent>
                  )}
                </Grid>
                {logsAndMessagesSortedDesc.map((item, index) => {
                  if (item.tipo === "log") {
                    return (
                      <Grid item xs={12} key={`log-${index}`}>
                        <LogInfo log={item.value as Log}></LogInfo>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} key={`mensagem-${index}`}>
                      <MensagemComponent
                        mensagem={item.value as Mensagem}
                      ></MensagemComponent>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default DivergenciaDetail;


 
function buildDefaultInfoComponents(
  divergencia: DivergenciaType,
  statusTransportadora: StatusTransportadora,
  onStatusChange: Function,
  userLogged: any,
  configData: any
): Array<JSX.Element | any> {
  const checkUserSecondaryResponsibility = (idUsuario?: number): boolean => {
    const usersSecondaryRespArea: number[] = 
      configData?.findOnePLM_Colaborativo_Configuracoes ? 
      JSON.parse(configData.findOnePLM_Colaborativo_Configuracoes.valor) : [];

    return idUsuario ? usersSecondaryRespArea.includes(idUsuario) : false;
  };

  const components: Array<JSX.Element> = [];
  const disabledForm = isSupplier(userLogged?.id_grupo || null);
  const userLoggedId = userLogged?.idUsuario;

  const addComponent = (component: any | null) => {
    if (component) components.push(component);
  };
  
  addComponent(<ColaboradorResponsavelComponent label="Aberto por" nome={divergencia.UsuarioAbriu.login} />);
  addComponent(<ColaboradorResponsavelComponent nome={divergencia.UsuarioResponsavel.login} />);
  addComponent(<InfoStatus divergencia={divergencia} onStatusChange={onStatusChange} />);
  addComponent(<TipoDivergencia divergencia={divergencia} onStatusChange={onStatusChange} />);
  addComponent(<SituacaoDivergencia divergencia={divergencia} onStatusChange={onStatusChange} />);
  addComponent(<LabelValueComponent label="Data de criação" value={moment(divergencia.created_at).format("LL")} />);
  addComponent(<LabelValueComponent label="Área responsável" value={divergencia.TipoDivergencia.area} />);
  addComponent(<SubgroupSelect divergencia={divergencia} />);
  
  addComponent(
    statusTransportadora?.status_transportadora && (
      <LabelValueComponent
        label="Status Transportadora"
        value={statusTransportadora.status_transportadora}
        hideChildren={!statusTransportadora.status_transportadora}
        hideLabel={!statusTransportadora.status_transportadora}
      />
    )
  );

  addComponent(
    <ReminderDate
      idDivergence={divergencia.id_divergencia}
      reminder={divergencia?.Divergencias_Lembretes}
      onStatusChange={onStatusChange}
    />
  );

  if (!disabledForm && checkUserSecondaryResponsibility(userLoggedId)) {
    addComponent(<AreaRespSecundaria divergencia={divergencia} onStatusChange={onStatusChange} />);
    
    if (divergencia.AreaRespSecundaria) {
      addComponent(<CategoriaAreaRespSecundaria divergencia={divergencia} onStatusChange={onStatusChange} />);
      addComponent(
        <ObsAreaRespSecundaria
          ObsDataProps={{
            idDivergence: divergencia.id_divergencia,
            observation: divergencia?.obs_area_secundaria,
          }}
          divergencia={divergencia}
        />
      );
    }
  }

  return components;
}